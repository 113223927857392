.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
  max-height: 250px;
  overflow-y: auto;
}

/* Customize scroll bar */
.nice-select.open .list::-webkit-scrollbar {
  width: 10px;
}

.nice-select.open .list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.nice-select.open .list::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.nice-select.open .list::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 172, 172);
}
